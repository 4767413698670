import ThemisReadOnly from "@/components/shared/read-only"

export default {
  name      : "ReportMetadata",
  components: {
    ThemisReadOnly
  },
  data: () => ({
    canViewChannel     : false,
    canViewLanguage    : false,
    canViewCountry     : false,
    canViewSource      : false,
    canViewStatus      : false,
    canViewUpdatedOn   : false,
    canViewClosedOn    : false,
    canViewConsentGiven: false
  }),
  props: {
    pReport          : Object,
    pChannel         : Object,
    pLanguages       : Array,
    pReportViewPolicy: Object
  },
  computed: {
    isReportNotInClientDefaultLanguage() {
      return this.pReport.languageId !== this.$CONSTANTS.LANGUAGE.CLIENT_DEFAULT.id
    },
    reportLanguage() {
      return this.pLanguages.find(language => language.id === this.pReport.languageId)
    },
    readOnlyFields() {
      const readOnlyFields = []

      if (this.pChannel) {
        readOnlyFields.push({
          visibility: this.canViewChannel,
          label     : this.$t("468"),
          value     : this.pChannel.name
        })
      }
      if (this.reportLanguage) {
        readOnlyFields.push({
          visibility: this.canViewLanguage,
          label     : this.$t("398"),
          value     : this.reportLanguage.name
        })
      }
      if (this.pReport.updatedAt) {
        readOnlyFields.push({
          visibility: this.canViewUpdatedOn,
          label     : this.$t("470"),
          value     : this.$moment(this.pReport?.updatedAt).format("D MMMM YYYY HH:mm (UTCZ)")
        })
      }
      if (this.pReport.source) {
        readOnlyFields.push({
          visibility: this.canViewSource,
          label     : this.$t("469"),
          value     : this.pReport.source
        })
        if (this.pReport.source === this.$CONSTANTS.REPORT_SOURCE.PHONE) {
          readOnlyFields.push({
            visibility: this.canViewIntakeType,
            label     : this.$t("2567"),
            value     : this.$t(this.$CONSTANTS.INTAKE_TYPE_MAP[this.pReport.intakeType])
          })
        }
      }
      readOnlyFields.push({
        visibility: this.isReportNotInClientDefaultLanguage && this.canViewLanguage,
        label     : this.$t("397"),
        value     : this.$CONSTANTS.LANGUAGE.CLIENT_DEFAULT.name
      })
      if (this.pReport.closedAt) {
        readOnlyFields.push({
          visibility: this.pReport.closedAt && this.canViewClosedOn,
          label     : this.$t("471"),
          value     : this.$moment(this.pReport.closedAt).format("D MMMM YYYY HH:mm (UTCZ)")
        })
      }
      if (this.pReport.country) {
        readOnlyFields.push({
          visibility: this.canViewCountry && !!this.pReport.country,
          label     : this.$t("624"),
          value     : this.pReport.country
        })
      }
      if (this.pReport.consentGiven) {
        readOnlyFields.push({
          visibility: this.canViewConsentGiven,
          label     : this.$t("859"),
          value     : this.$moment(this.pReport.createdAt).format("D MMMM YYYY HH:mm (UTCZ)")
        })
      }
      return readOnlyFields
    }
  },
  methods: {
  },
  watch: {
    pReportViewPolicy: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && newValue.select) {
          this.canViewChannel      = newValue.select.includes("channelId")
          this.canViewLanguage     = newValue.select.includes("languageId")
          this.canViewCountry      = newValue.select.includes("country")
          this.canViewSource       = newValue.select.includes("source")
          this.canViewIntakeType   = newValue.select.includes("intakeType")
          this.canViewStatus       = newValue.select.includes("status")
          this.canViewUpdatedOn    = newValue.select.includes("updatedAt")
          this.canViewClosedOn     = newValue.select.includes("closedAt")
          this.canViewConsentGiven = newValue.select.includes("consentGiven")
        }
      }
    }
  }
}